class DateUtils {

  /**
   * Converts a Date object without time (expressed as a date in UTC, eg. 2021-12-20T00:00:00.000Z) to a Date object
   * in local time, so that getDate(), getHours() etc. will return 20 and 00.
   * @param date
   * @returns {Date}
   */
  static timelessDateToLocalDate = (date) => {
    const localTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + localTimezoneOffset);
  }

  /**
   * Converts a Date object in local time to a date Object without time
   * (expressed as a date in UTC, eg. 2021-12-20T00:00:00.000Z)
   * @param date
   * @returns {Date}
   */
  static localDateToTimelessDate = (date) => {
    const localTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - localTimezoneOffset);
  }

  // Converts a timeless date object (expressed as 2021-12-20T00:00:00.000Z) to a string.
  // Format is "YYYY-MM-DD"
  static timelessDateToString = (date) => {
    const localDate = this.timelessDateToLocalDate(date);

    // Get year, month and day
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, "0");
    const day = String(localDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  static timelessDateToLongString = (date, language) => {
    const localDate = this.timelessDateToLocalDate(date);

    const months = {
      'en': ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      'fr': ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"]
    };

    const year = localDate.getFullYear();
    const month = months[language][localDate.getMonth()];
    const day = localDate.getDate();

    if (language === 'fr') {
      return `${day} ${month} ${year}`
    } else
      return `${month} ${day}, ${year}`;
  }
}

export default DateUtils;
