import React from "react";
import { Parser } from "html-to-react";

export default class HtmlUtils {
  /**
   * Removes the html tags of a string and returns the text content. Html entities are parsed.
   * @param html Html string
   * @returns {*} Text from html string
   */
  static removeHtml(html) {
    return HtmlUtils.getNodeText(HtmlUtils.htmlToComponents(html));
  }

  /**
   * Converts newlines in a string to <br/> tags.
   * @param text Text to split
   * @returns {string|unknown[]|number}
   */
  static nlToBr(text) {
    const newline = '\n';

    // If null, undefined, not a number nor a string, return nothing. If a number, return the number. If empty string, return nothing.
    if (typeof text === 'number') {
      return text;
    } else if (typeof text !== 'string') {
      return '';
    } else if (text === '') {
      return '';
    }

    let lines = text.split(newline);
    return lines.map((line, index) =>
      <React.Fragment key={index}>
        {line}<br/>
      </React.Fragment>
    );
  }

  /**
   * Converts an html string into React elements that can be rendered in a JSX tree.
   * @param html Html string with tags
   * @returns {*} React elements (there can be many if html has no root tag)
   */
  static htmlToComponents(html) {
    const htmlToReactParser = new Parser();
    return htmlToReactParser.parse(html);
  }

  /**
   * Get the text content of a React node (similar to getting the text content of an html element)
   * @param node React node
   * @returns {string|*} Text content
   */
  static getNodeText(node) {
    if (['string', 'number'].includes(typeof node))
      return node;
    if (node instanceof Array)
      return node.map(HtmlUtils.getNodeText).join('');
    if (typeof node === 'object' && node)
      return HtmlUtils.getNodeText(node.props.children);
  }
}
