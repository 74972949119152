import React from "react";
import WaitForData from "../../../common/data-fetching/WaitForData";
import useQueryReportsForJob from "./useQueryReportsForJob";
import RouterUtils from "../../../../utils/RouterUtils";
import * as ROUTES from "../../../../constants/routes";
import ReportListItem from "./ReportListItem";
import ReportPreview from "./ReportPreview";
import {useNavigate} from "react-router-dom";
import CardList from "../../common/CardList";

export default function ReportList(props) {

  const {reportId} = props;

  const {data: reports, loading: reportsLoading, errors: reportsErrors} = useQueryReportsForJob(reportId);

  const navigate = useNavigate();

  const onClickReport = (reportId) => {
    navigate(RouterUtils.injectParamsInRoute(ROUTES.REPORT_DETAILS, {reportId}))
  }

  return (
    <div className={"ReportList"}>
      <WaitForData loading={reportsLoading} errors={reportsErrors} onLoaded={() =>
        <CardList items={reports} getItemKey={report => report._id} renderItem={report =>
          <ReportListItem>
            <ReportPreview report={report} onClick={() => onClickReport(report._id)}/>
          </ReportListItem>
        }/>
      }/>
    </div>
  )
};
