import React from "react";
import {Col} from "react-bootstrap";
import {Row} from "react-bootstrap";
import ExoTalentLogo from "../../img/logo.svg";
import ExoTalentCard from "../../img/exo-talent.png";
import HubbleCard from "../../img/hubble.png";
import StratoCard from "../../img/strato-exec.png";
import StAmourCard from "../../img/st-amour.png";
import FlairTechCard from "../../img/flair-tech.png";
import Magellan from "../../img/groupe_magellan_blanc_RGB.png";
import LinkedIn from "../../img/linkedin-svg.svg";
import Image from "react-bootstrap/Image";
import "./Footer.scss";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import useTranslationFooter from "./useTranslationFooter";
import * as ROUTES from "../../constants/routes";
import useLanguages from "../infra-no-ui/translation/useLanguages";
import RouterUtils from "../../utils/RouterUtils";
import Container from "react-bootstrap/Container";

export default function Footer() {

  const {t, loading: tLoading} = useTranslationFooter();
  useShowLoader(tLoading, "Footer");

  // Get the current language code
  const {getCurrentLanguageCode} = useLanguages();
  const currentLanguage = getCurrentLanguageCode();

  const exotalentRoute = RouterUtils.injectParamsInRoute(ROUTES.EXOTALENT_HOME, {language: currentLanguage});
  const magellanRoute = RouterUtils.injectParamsInRoute(ROUTES.MAGELLAN_HOME, {language: currentLanguage});
  const stAmourRoute = RouterUtils.injectParamsInRoute(ROUTES.ST_AMOUR_HOME, {language: currentLanguage});
  const flairTechRoute = RouterUtils.injectParamsInRoute(ROUTES.FLAIR_TECH_HOME, {language: currentLanguage});
  const stratoExecRoute = RouterUtils.injectParamsInRoute(ROUTES.STRATO_EXEC_HOME, {language: currentLanguage});
  const hubbleRoute = RouterUtils.injectParamsInRoute(ROUTES.HUBBLE_HOME, {language: currentLanguage});

  const exotalentEmail = t("footer:exotalent_email");
  const exotalentEmailLink = <a className={"exotalent-email"} href={`mailto:${exotalentEmail}`}>{exotalentEmail}</a>;

  return (
    <div className="Footer">
      <Row className={"logo-row"}>
        <Col>
          <a href={exotalentRoute}><Image src={ExoTalentLogo} className={"exotalent-logo"} alt={t("footer:exotalent_logo_alt")}/></a>
          {exotalentEmailLink}
        </Col>
        <Col className={"sourcing-col"}>
          {t("footer:sourcing_msg")}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={"horizontal-line"}/>
        </Col>
      </Row>
      <Row className={"magellan-row"}>
        <Col className={"magellan-col"}>
          <Row>
            <Col className={"division-col"}>
              {t("footer:exotalent_division_msg")}
              <a href={magellanRoute}><Image src={Magellan} alt={t("footer:magellan_logo_alt")} className={"magellan-logo"}/></a>
            </Col>
          </Row>
          <Row>
            <Col className={"card-group-col"}>
              {/* Use a container for spacing when wrapping, see https://getbootstrap.com/docs/5.0/layout/gutters/ */}
              <Container className={"card-container"}>
                <Row className={"card-row"}>
                  <Col className={"card-col"}>
                    <a href={stAmourRoute}><Image src={StAmourCard} alt={t("footer:card_st_amour_alt")}/></a>
                  </Col>
                  <Col className={"card-col"}>
                    <a href={hubbleRoute}><Image src={HubbleCard} alt={t("footer:card_hubble_alt")}/></a>
                  </Col>
                  <Col className={"card-col"}>
                    <a href={stratoExecRoute}><Image src={StratoCard} alt={t("footer:card_strato_exec_alt")}/></a>
                  </Col>
                  <Col className={"card-col"}>
                    <a href={exotalentRoute}><Image src={ExoTalentCard} alt={t("footer:card_exotalent_alt")}/></a>
                  </Col>
                  <Col className={"card-col"}>
                    <a href={flairTechRoute}><Image src={FlairTechCard} alt={t("footer:card_flair_tech_alt")}/></a>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Col>
        <Col className={"address-col"}>
          <Row>
            <Col className={"postal-address-col"}>
              <p>{t("footer:address_mtl")}</p>
              <p>{t("footer:address_mis")}</p>
            </Col>
          </Row>
          <Row className={"phone-row"}>
            <Col>
              <div className={"phone-number"}>{t("footer:phone_number")}</div>
            </Col>
          </Row>
          <Row className={"linkedin-row"}>
            <Col className={"linkedin-col"}>
              <a href={ROUTES.LINKED_IN}><Image src={LinkedIn} alt={t("footer:linkedin_logo_alt")} className={"linkedin-logo"}/></a>
            </Col>
            <Col className={"contact-col"}>
              <div>{exotalentEmailLink}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
