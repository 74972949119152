import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import CandidateList from "../../candidates/view/CandidateList";
import "./ReportDetailsBlock.scss";
import PersonImage from "../../../../img/pers-icon.svg";
import Image from "react-bootstrap/Image";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import HtmlUtils from "../../../../utils/HtmlUtils";
import StringUtils from "../../../../utils/StringUtils";
import useFormatTimelessDateToLongString from "../../../common/formatting/useFormatTimelessDateToLongString";
import {useNavigate} from "react-router-dom";
import RouterUtils from "../../../../utils/RouterUtils";
import * as ROUTES from "../../../../constants/routes";
import ActionIcon from "../../../common/widgets/ActionIcon";
import BackIcon from "@mui/icons-material/KeyboardBackspace";

export default function ReportDetailsBlock(props) {

  const {report} = props;
  const formattedReportDate = useFormatTimelessDateToLongString(new Date(report.date));

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ReportDetailsBlock");

  const navigate = useNavigate();

  const goBack = () => {
    navigate(RouterUtils.injectParamsInRoute(ROUTES.REPORT_SUMMARY, {reportId: report._id}))
  }

  const reportTitle = <span>{report.job.title}</span>;
  const reportSubtitle = <span className={"client-name"}>{report.job.client.name}</span>;

  // Parse report note only when report object changes
  const reportNote = React.useMemo(() => {
    return report ? HtmlUtils.htmlToComponents(report.note) : "";
  }, [report]);

  return (
    <div className="ReportDetailsBlock mainRowContainer">
      <Row className={"topRow"}>
        <Col className={"title-col titleRowContainer"}>
          <Row>
            <Col className={"back-button-container"}>
              <div className={"back-button"}>
                <ActionIcon onClick={goBack} icon={BackIcon}>{t("market:go_back_action")}</ActionIcon>
              </div>
              <h1>{reportTitle}</h1>
            </Col>
          </Row>
          <Row >
            <Col>
              <h2>{reportSubtitle}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>{formattedReportDate}</h2>
            </Col>
          </Row>
        </Col>
        <Col className={"image-col"}>
          <Image src={PersonImage} alt={t("reporting:report_person_placeholder")} className={"person-image"}/>
        </Col>
      </Row>
      {!StringUtils.isNullOrEmpty(reportNote) &&
      <Row>
        <Col>
          {reportNote}
        </Col>
      </Row>
      }
      <Row>
        <Col>
          <CandidateList reportId={report._id} reportTitle={reportTitle} reportSubtitle={reportSubtitle}/>
        </Col>
      </Row>
    </div>
  );
};
