import {useContext} from "react";
import ReactDOM from "react-dom";
import FooterContainerRefContext from "./FooterContainerRefContext";

/**
 * Displays the Footer component in an html node somewhere else in the React tree, using a portal to the target container div.
 * If reference to target node is null, does not display anything.
 * @return {*}
 * @constructor
 */
export default function FooterPortal(props) {

  const {component} = props;
  const FooterContainerRef = useContext(FooterContainerRefContext);

  // RENDER

  return FooterContainerRef.current !== null ? ReactDOM.createPortal(component, FooterContainerRef.current) : null;
}
