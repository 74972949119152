import React from "react";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useTranslationMarket from "../../useTranslationMarket";
import {Col} from "react-bootstrap";
import {Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";
import RouterUtils from "../../../../utils/RouterUtils";
import "./TalentPoolHomeBlock.scss";
import BackgroundImage from "../../../../img/home-page-img.svg";
import Image from "react-bootstrap/Image";
import ActionButtonWithIconGo from "../../../common/widgets/ActionButtonWithIconGo";
import HtmlUtils from "../../../../utils/HtmlUtils";

export default function TalentPoolHomeBlock(props) {

  const {talentPoolId} = props;
  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "TalentPoolHomeBlock");
  const navigate = useNavigate();

  // Translation contains markup, convert it to React html elements
  const pageContent = HtmlUtils.htmlToComponents(t("market:home_content"));

  const onClickTalentPool = () => {
    navigate(RouterUtils.injectParamsInRoute(ROUTES.TALENT_POOL_DETAILS, {talentPoolId}))
  }

  return (
    <div className={"TalentPoolHomeBlock"}>
      <Row>
        <Col className={"content-col"}>
          <Row>
            <Col className={"title-col"}>
              {/* Put title in Page content instead of Page.Title to help compute the image offset */}
              <h1>{t("market:home_title")}</h1>
            </Col>
          </Row>
          <Row>
            <Col className={"action-row"}>
              <ActionButtonWithIconGo onClick={onClickTalentPool}>{t("market:home_view_talent_pool_action")}</ActionButtonWithIconGo>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={"text-content"}>
                {pageContent}
              </div>
            </Col>
          </Row>
        </Col>
        <Col className={"image-col"}>
          <Image src={BackgroundImage}/>
        </Col>
      </Row>

    </div>
  );
};
