import {useEffect} from "react";
import useMutationGlobalLog from "./useMutationGlobalLog";
import {useLocation} from "react-router-dom";
import usePreviousState from "../../infra-no-ui/react-helpers/usePreviousState";

export default function ApolloErrorLogger(props) {

  const {error} = props;
  const {mutate, loading} = useMutationGlobalLog()
  const location = useLocation();
  const previousError = usePreviousState(error);

  useEffect(() => {
    // Do not log when a previous mutation is loading, as a way to avoid some repetitive calls
    if (error !== undefined && !loading && error !== previousError) {

      // Stringifying the error with JSON usually returns an empty object.
      // Try accessing its message property, or toString() property, if any exists.
      let errorString;
      if (typeof error === "object" && "message" in error)
        errorString = error.message;
      else if (typeof error === "object" && "toString" in error)
        errorString = error.toString();
      else
        errorString = error;

      // Sometimes there is a stack property for the stacktrace
      const stacktrace = typeof error === "object" ? error.stack : undefined;

      // Information about current user (if logged in) is determined on the server side
      const payload = {
        location: location,
        error: errorString,
        stacktrace: stacktrace
      }

      mutate({
        variables: {
          payload: JSON.stringify(payload)
        }
      })
    }
  }, [error, loading, previousError, location, mutate])
  return null;
};
