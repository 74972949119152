import React from 'react';
import "./SplashScreen.scss";
import logo from "../../img/logo-exotalent.svg";
import Image from "react-bootstrap/Image";
import {Row, Col} from "react-bootstrap";

export default function SplashScreen() {

  return (
    <div className="SplashScreen">
      <Row className="logo-row">
        <Col>
          <Image src={logo} className="logo"/>
        </Col>
      </Row>
    </div>
  );
}
