import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import "./ReportSummaryStats.scss";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useQueryStatsForReport from "./useQueryStatsForReport";
import WaitForData from "../../../common/data-fetching/WaitForData";
import StringUtils from "../../../../utils/StringUtils";
import HtmlUtils from "../../../../utils/HtmlUtils";

export default function ReportSummaryStats(props) {

  const {reportId} = props;

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ReportSummaryStats");

  const {data: stats, loading: statsLoading, errors: statsErrors} = useQueryStatsForReport(reportId);

  return (
    <WaitForData loading={statsLoading} errors={statsErrors} onLoaded={() =>
      <div className="ReportSummaryStats">
        <Row>
          <Col className={"job-stats-col"}>
            <Row>
              <Col className={"title"}>
                {t("reporting:stats_job_title")}
              </Col>
            </Row>
            <Row>
              <Col>
                {HtmlUtils.htmlToComponents(t("reporting:stats_job_report_count", {count: StringUtils.nullToZero(stats.job.reportCount)}))}
              </Col>
            </Row>
            <Row>
              <Col>
                {HtmlUtils.htmlToComponents(t("reporting:stats_job_candidate_count", {count: StringUtils.nullToZero(stats.job.candidateCount)}))}
              </Col>
            </Row>
          </Col>
          <Col className={"client-stats-col"}>
            <Row>
              <Col className={"title"}>
                {t("reporting:stats_client_title")}
              </Col>
            </Row>
            <Row>
              <Col>
                {HtmlUtils.htmlToComponents(t("reporting:stats_client_job_count", {count: StringUtils.nullToZero(stats.job.client.jobCount)}))}
              </Col>
            </Row>
            <Row>
              <Col>
                {HtmlUtils.htmlToComponents(t("reporting:stats_client_candidate_count", {count: StringUtils.nullToZero(stats.job.client.candidateCount)}))}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    }/>
  );
};
