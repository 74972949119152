import React from "react";
import "./MarketCardList.scss";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";

export default function MarketCardList(props) {

  const {items, getItemKey, renderItem} = props;

  return (
    <div className="MarketCardList cardRowContainer">
      <Row className={"card-row"}>
        {items.map(item =>
          <Col key={getItemKey(item)} className={"card-col"}>
            {renderItem(item)}
          </Col>
        )}
      </Row>
    </div>
  );
};
