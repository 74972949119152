import React from "react";
import ActionButton from "./ActionButton";
import "./ActionButtonWithIcon.scss";

/**
 * ActionButton with an icon at the end of text.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ActionButtonWithIcon(props) {

  const {Icon, children, className, ...otherProps} = props;

  const newClassName = ["ActionButtonWithIcon", className].join(" ");

  return (
    <ActionButton className={newClassName} {...otherProps}>{children} <Icon className={"icon"}/></ActionButton>
  );
};
