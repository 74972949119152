import React from "react";
import useShowLoader from "../loading-widgets/useShowLoader";
import LoadingError from "../loading-widgets/LoadingError";
import TypeUtils from "../../../utils/TypeUtils";

/**
 * Component that renders content when data is ready (no props.error and props.loading is false).
 * Content to display is given by props.onLoaded() function.
 * We use the render prop pattern (displaying a function instead of props.children) because we don't want the component calling WaitForData
 * to evaluate the children of WaitForData unless the data is loaded, otherwise null exceptions may occur.
 * While data is loading (props.loading is true), display nothing by itself, but delegate the display of a spinner to the nearest Loader parent.
 * If an error occurred during loading (props.error is not null), display the standard error component or the content of props.onError if provided.
 * @param props
 * @return {null|*}
 * @constructor
 */
export default function WaitForData(props) {

  const {loading, errors, onLoaded, onError} = props;

  // Delegate the display of the loading spinner to a component higher in hierarchy
  useShowLoader(loading, "WaitForData");

  // If loading, do not display children (it might create null errors)
  if (loading)
    return null;

  // If an error occurred, display the onError function/component if one was passed, otherwise display the standard loading error.
  if (!TypeUtils.arrayIsEmpty(errors)) {
    if (onError)
      return onError();
    else
      return <LoadingError/>
  }

  // Success, display onLoaded
  return onLoaded();

}
