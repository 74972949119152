import React from "react";
import {useParams} from "react-router";
import WaitForData from "../../../common/data-fetching/WaitForData";
import Page from "../../../common/page/Page";
import useQueryJobCategoryById from "./useQueryJobCategoryById";
import JobCategoryDetailsBlock from "./JobCategoryDetailsBlock";
import * as ROUTES from "../../../../constants/routes";
import {Navigate} from "react-router-dom";

export default function JobCategoryDetailsPage(/*props*/) {

  // Fetch jobCategory from ID provided in the url params.
  const urlParams = useParams();
  const jobCategoryId = urlParams.jobCategoryId;
  const {data: jobCategory, loading: jobCategoryLoading, errors: jobCategoryErrors} = useQueryJobCategoryById(jobCategoryId);

  return (
    <WaitForData loading={jobCategoryLoading} errors={jobCategoryErrors} onLoaded={() =>
      <Page className="JobCategoryDetailsPage">
        <Page.Title text={jobCategory ? jobCategory.name : ""}/>
        <Page.Content className="spaced-children">
          {jobCategory ? <JobCategoryDetailsBlock jobCategory={jobCategory} /> : <Navigate replace to={ROUTES.NOT_FOUND}/>}
        </Page.Content>
      </Page>
    }/>
  );
}
