import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import BasePage from "../common/page/BasePage";
import TranslationProvider from "../infra-no-ui/translation/TranslationProvider";
import NotFoundPage from "./NotFoundPage";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import UnauthenticatedApolloProvider from "../infra-no-ui/apollo/UnauthenticatedApolloProvider";
import MenuPortal from "../common/page/MenuPortal";
import Menu from "../menu/Menu";
import ReportHomePage from "../reporting/reports/view/ReportHomePage";
import ReportDetailsPage from "../reporting/reports/view/ReportDetailsPage";
import FooterContainer from "../footer/FooterContainer";
import FooterPortal from "../common/page/FooterPortal";
import ReportSummaryPage from "../reporting/reports/view/ReportSummaryPage";
import {apolloCacheManager} from "./apolloCacheManager";
import ErrorBoundary from "../common/error-boundaries/ErrorBoundary";
import TranslatedContentError from "../common/error-boundaries/TranslatedContentError";
import ErrorTestPage from "./ErrorTestPage";
import ApolloErrorLogger from "../common/error-boundaries/ApolloErrorLogger";
import TalentPoolHomePage from "../market/pools/view/TalentPoolHomePage";
import TalentPoolDetailsPage from "../market/pools/view/TalentPoolDetailsPage";
import PoolJobDetailsPage from "../market/jobs/view/PoolJobDetailsPage";
import JobCategoryDetailsPage from "../market/categories/view/JobCategoryDetailsPage";

export default function AppRouter() {
  return <Router><AppRouterContent/></Router>
}

function AppRouterContent() {
  return (
    // <Router> must come before BasePage, because BasePage ultimately renders links
    // Display BasePage before everything else, so that we can use it to display translation and authentication errors.
    // BasePage will use portals to display menu and footer, which depend on translation and authentication that are not yet ready
    <BasePage>
      <TranslationProvider useShowLoader={useShowLoader}>
        <ErrorBoundary component={<TranslatedContentError />}>
          <UnauthenticatedApolloProvider cacheManager={apolloCacheManager()}>

            {/* At this point we can log frontend errors using Apollo because it is now available */}
            <ErrorBoundary component={<TranslatedContentError />} logger={ApolloErrorLogger}>
              <MenuPortal component={<Menu/>}/>
              <FooterPortal component={<FooterContainer/>}/>
              <Routes>
                {/* Try to keep declarations here and in routes.js in the same order, for maintainability */}

                {/* Sourcing reports */}
                <Route path={ROUTES.REPORT_HOME} element={<ReportHomePage/>}/>
                <Route path={ROUTES.REPORT_SUMMARY} element={<ReportSummaryPage/>}/>
                <Route path={ROUTES.REPORT_DETAILS} element={<ReportDetailsPage/>}/>

                {/* Talent market reports */}
                <Route path={ROUTES.TALENT_POOL_HOME} element={<TalentPoolHomePage/>}/>
                <Route path={ROUTES.TALENT_POOL_DETAILS} element={<TalentPoolDetailsPage/>}/>
                <Route path={ROUTES.POOL_JOB_DETAILS} element={<PoolJobDetailsPage/>}/>
                <Route path={ROUTES.JOB_CATEGORY_DETAILS} element={<JobCategoryDetailsPage/>}/>

                {/* Error test page */}
                <Route path={ROUTES.ERROR_TEST} element={<ErrorTestPage/>}/>

                <Route path="*" element={<NotFoundPage/>}/>
              </Routes>
            </ErrorBoundary>
          </UnauthenticatedApolloProvider>
        </ErrorBoundary>
      </TranslationProvider>
    </BasePage>
  );
}
