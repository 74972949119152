import React from "react";
import {useParams} from "react-router";
import WaitForData from "../../../common/data-fetching/WaitForData";
import Page from "../../../common/page/Page";
import useQueryPoolJobById from "./useQueryPoolJobById";
import PoolJobDetailsBlock from "./PoolJobDetailsBlock";
import * as ROUTES from "../../../../constants/routes";
import {Navigate} from "react-router-dom";

export default function PoolJobDetailsPage(/*props*/) {

  // Fetch poolJob from ID provided in the url params.
  const urlParams = useParams();
  const poolJobId = urlParams.poolJobId;
  const {data: poolJob, loading: poolJobLoading, errors: poolJobErrors} = useQueryPoolJobById(poolJobId);

  return (
    <WaitForData loading={poolJobLoading} errors={poolJobErrors} onLoaded={() =>
      <Page className="PoolJobDetailsPage">
        <Page.Title text={poolJob ? poolJob.name : ""}/>
        <Page.Content className="spaced-children">
          {poolJob ? <PoolJobDetailsBlock poolJob={poolJob} /> : <Navigate replace to={ROUTES.NOT_FOUND}/>}
        </Page.Content>
      </Page>
    }/>
  );
}
