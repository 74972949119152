import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import "./TalentPoolDetailsBlock.scss";
import Loader from "../../../common/loading-widgets/Loader";
import TalentPoolDetailsStats from "./TalentPoolDetailsStats";
import PoolJobList from "../../jobs/view/PoolJobList";
import ActionIcon from "../../../common/widgets/ActionIcon";
import BackIcon from "@mui/icons-material/KeyboardBackspace";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import RouterUtils from "../../../../utils/RouterUtils";
import * as ROUTES from "../../../../constants/routes";
import {useNavigate} from "react-router-dom";

export default function TalentPoolDetailsBlock(props) {

  const {talentPool} = props;

  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "PoolJobDetailsBlock");

  const navigate = useNavigate();

  const talentPoolTitle = <span>{talentPool.name}</span>;

  const goBack = () => {
    navigate(RouterUtils.injectParamsInRoute(ROUTES.TALENT_POOL_HOME, {talentPoolId: talentPool._id}));
  }

  return (
    <div className="TalentPoolDetailsBlock mainRowContainer">
      <Row className={"topRow"}>
        <Col className={"title-col titleRowContainer"}>
          <Row>
            <Col className={"back-button-container"}>
              <div className={"back-button"}>
                <ActionIcon onClick={goBack} icon={BackIcon}>{t("market:go_back_action")}</ActionIcon>
              </div>
              <h1>{talentPoolTitle}</h1>
            </Col>
          </Row>
        </Col>
        <Col className={"stats-col"}>
          <Loader>
            <TalentPoolDetailsStats talentPoolId={talentPool._id}/>
          </Loader>
        </Col>
      </Row>
      <Row>
        <Col>
          <PoolJobList talentPoolId={talentPool._id}/>
        </Col>
      </Row>
    </div>
  );
};
