import React from "react";
import useLazyQueryPoolCandidateResumeDownloadUrl from "./useLazyQueryPoolCandidateResumeDownloadUrl";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import StringUtils from "../../../../utils/StringUtils";
import useErrorModal from "../../../common/modals/useErrorModal";
import useTranslationMarket from "../../useTranslationMarket";
import ActionDiv from "../../../common/widgets/ActionDiv";
import ResumeImage from "../../../../img/resume-download.png";
import Image from "react-bootstrap/Image";
import ActionLink from "../../../common/widgets/ActionLink";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import "./PoolCandidateResumeDownloadIcon.scss";
import useServerErrorFormatter from "../../../common/modals/useServerErrorFormatter";

export default function PoolCandidateResumeDownloadIcon(props) {

  const onClickDownload = (event) => {
    event.preventDefault();
    setQueryLoading(true);

    return fetch({id: candidateId}).then(downloadUrl => {

      if (!StringUtils.isNullOrEmpty(downloadUrl)) {
        // This will trigger the file download (window.open(downloadUrl) produces a glitch)
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.click();
      }
    }).catch(error => {
      setError(error);
      showErrorModal();
    }).finally(() => {
      setQueryLoading(false);
    })
  };

  const {candidateId, disabled} = props;
  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "PoolCandidateResumeDownloadIcon");
  const {fetch} = useLazyQueryPoolCandidateResumeDownloadUrl();
  // error returned by useLazyQueryResumeDownloadUrl is not updated after fetch, we have to manage it ourselves
  const [error, setError] = React.useState(null);
  const errorMsg = useServerErrorFormatter(error);
  const {ErrorModal, show: showErrorModal} = useErrorModal(
    <>
      <p>{t("market:pool_candidates_resume_download_error")}</p>
      {errorMsg}
    </>
  );

  // loading status returned by useLazyQueryClientDownloadUrl is not updated after fetch, we have to manage it ourselves
  const [queryLoading, setQueryLoading] = React.useState(false);

  return (
    <>{ErrorModal}
      <div className={"PoolCandidateResumeDownloadIcon"}>
        <Row>
          <Col>
            <ActionDiv onClick={onClickDownload} disabled={disabled}>
              <Image src={ResumeImage}
                     alt={t("market:pool_candidates_details_download_resume_action")}/>
            </ActionDiv>
          </Col>
        </Row>
        <Row>
          <Col>
            <ActionLink onClick={onClickDownload} loading={queryLoading}>{t("market:pool_candidates_details_download_resume_action")}</ActionLink>
          </Col>
        </Row>
      </div>
    </>
  );
}
