import React from "react";
import Container from "react-bootstrap/Container";
import "./BasePage.scss";
import scrollToComponent from "../page/scrollToComponent.js";
import ScrollToTopOnMount from "./ScrollToTopOnMount";
import HeaderContainer from "./HeaderContainer";
import MenuContainerRefContext from "./MenuContainerRefContext";
import FooterContainerRefContext from "./FooterContainerRefContext";
import Loader from "../loading-widgets/Loader";

/**
 * Displays the simplest version of a page: a header menu, a zone for the contents and a footer.
 *
 * The contents is passed as a child.
 *
 * The header and footer are passed using portals. Because the BasePage is used to display error messages
 * coming from authenticating the user or initializing translations, the header, sidebar and footer must
 * be displayable even if the user or translation are not ready.
 *
 * Therefore, the header and footer must each have a component that reserves space on the page but does not
 * display contents that depends on authentication or translation. These components are called and rendered by the
 * BasePage component. Reserving space avoids the loading icon to move as the authentication and/or translation are
 * ready.
 *
 * To display the part of the header that depends on authentication and translation, insert a MenuPortal
 * component deeper in the tree (when the authentication and/or translation are ready) and
 * pass it the component responsible of generating the header content.
 *
 * For the footer, insert a FooterPortal.
 *
 * Implementation details:
 *
 * The BasePage component declares ref placeholders for the header, sidebar and footer. The ref placeholders
 * created here are forwarded down the tree through their own context. Each portal down the tree catches its relevant
 * context and "instantiate" the ref when the portal is ready to be rendered. The content generated by the portal
 * is rendered at the appropriate location in the BasePage, as if it were declared there in the first place.
 *
 * The BasePage component cannot be converted to a functional component because we need to create a ref here.
 */
export default class BasePage extends React.Component {

  menuContainerRef = React.createRef();
  footerContainerRef = React.createRef();

  render() {
    return (
      <div className="BasePage">
        <ScrollToTopOnMount/>
        <nav className="header-nav">
          <HeaderContainer ref={this.menuContainerRef}/>
        </nav>
        <Loader className={"main-container-loader"}>
          <div className="compensate-right-scrollbar">
            <Container className="main-container">
              <MenuContainerRefContext.Provider value={this.menuContainerRef}>
                <FooterContainerRefContext.Provider value={this.footerContainerRef}>
                  {this.props.children}
                </FooterContainerRefContext.Provider>
              </MenuContainerRefContext.Provider>
            </Container>
          </div>
          <footer ref={this.footerContainerRef}/>
        </Loader>
      </div>
    );
  }

  /**
   * Scroll page so that element passed as parameter appears on top. Take into account the height of the
   * fixed-top menu so that the element is not behind the menu. This function assumes that there is a menu
   * (the HTML tag is not important) with a class "fixed-top".
   * @param scrollTo React reference to the element to bring on top.
   * @param duration Duration of animation in ms
   */
  static scrollToComponentAfterMenu = (scrollTo, duration = 500) => {
    if (scrollTo) {
      const fixedTopElements = document.getElementsByClassName('fixed-top');
      // If there is one fixed element on page (such as the menu), scroll after the menu, otherwise
      // scroll to the very top of page.
      // Ensure that there is a little space (1 px) between the top and the element to scroll to top so
      // that the element doesn't touch the top
      const offset = fixedTopElements.length > 0 ? fixedTopElements[0].clientHeight + 1: 1;
      scrollToComponent(scrollTo, {offset: -offset, align: 'top', duration: duration});
    }
  };
}
