import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import WaitForData from "../../../common/data-fetching/WaitForData";
import useQueryCandidateById from "./useQueryCandidateById";
import StringUtils from "../../../../utils/StringUtils";
import ResumeDownloadIcon from "./ResumeDownloadIcon";
import TypeUtils from "../../../../utils/TypeUtils";
import "./CandidateDetails.scss";
import ActionButtonWithIconCancel from "../../../common/widgets/ActionButtonWithIconCancel";
import HtmlUtils from "../../../../utils/HtmlUtils";
import useMutationCandidateMarkViewed from "../mutate/useMutationCandidateMarkViewed";
import CandidateAnswer from "./CandidateAnswer";

export default function CandidateDetails(props) {

  const {candidateId, onClose, reportTitle, reportSubtitle} = props;

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "CandidateDetails");

  const {data: candidate, loading: candidateLoading, errors: candidateErrors} = useQueryCandidateById(candidateId);
  const {mutate} = useMutationCandidateMarkViewed();

  const hasResume = candidate && candidate.resumeFile && !StringUtils.isNullOrEmpty(candidate.resumeFile.filename);

  // Parse candidate note only when candidate object changes
  const candidateNote = React.useMemo(() => {
    return candidate ? HtmlUtils.htmlToComponents(candidate.note) : "";
  }, [candidate]);

  // Mark the candidate as viewed as soon as the candidate is loaded.
  // Don't do that on the backend side when calling candidateById in case the endpoint is called without using the frontend
  React.useEffect(() => {
    if (candidate && !candidate.viewed) {
      mutate({
        variables: {
          candidateId: candidate._id
        }
      });
    }
  }, [mutate, candidate]);

  return (
    <div className={"CandidateDetails mainRowContainer"}>
      <WaitForData loading={candidateLoading} errors={candidateErrors} onLoaded={() =>
        <>
          <Row className={"topRow"}>
            <Col className="title-col titleRowContainer">
              <Row>
                <Col>
                  <h1>{reportTitle}</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2>{reportSubtitle}</h2>
                </Col>
              </Row>
            </Col>
            {hasResume &&
            <Col className="resume-col">
              <ResumeDownloadIcon candidateId={candidate._id}/>
            </Col>
            }
          </Row>
          <Row>
            <Col className={"name-col"}>
              <h3>{candidate.name}</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              {TypeUtils.ensureArray(candidate.contactLines).map((contactLine, index) =>
                <Row key={index}>
                  <Col>
                    {contactLine}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="note-col">
              {candidateNote}
            </Col>
          </Row>
          {candidate.answers &&
            <>
              <Row>
                <Col>
                  <hr className={"questionnaire-hr"}/>
                </Col>
              </Row>
              {candidate.answers.map(candidateAnswer =>
                <Row key={candidateAnswer._id}>
                  <Col>
                    <CandidateAnswer question={candidateAnswer.question}
                                     answer={candidateAnswer.answer}/>
                  </Col>
                </Row>
              )}
            </>
          }
        </>
      }/>
      {/* Display button even if data cannot be loaded */}
      <Row className="button-row">
        <Col>
          <ActionButtonWithIconCancel onClick={onClose}>{t("reporting:candidates_details_close_action")}</ActionButtonWithIconCancel>
        </Col>
     </Row>
    </div>
  )
}
