import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import WaitForData from "../../../common/data-fetching/WaitForData";
import useQueryPoolCandidateById from "./useQueryPoolCandidateById";
import StringUtils from "../../../../utils/StringUtils";
import PoolCandidateResumeDownloadIcon from "./PoolCandidateResumeDownloadIcon";
import "./PoolCandidateDetails.scss";
import ActionButtonWithIconCancel from "../../../common/widgets/ActionButtonWithIconCancel";
import HtmlUtils from "../../../../utils/HtmlUtils";

export default function PoolCandidateDetails(props) {

  const {poolCandidateId, onClose, title, subtitle1, subtitle2} = props;

  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "PoolCandidateDetails");

  const {data: candidate, loading: candidateLoading, errors: candidateErrors} = useQueryPoolCandidateById(poolCandidateId);

  const hasResume = candidate && candidate.resumeFile && !StringUtils.isNullOrEmpty(candidate.resumeFile.filename);

  // Parse candidate note only when candidate object changes
  const candidateNote = React.useMemo(() => {
    return candidate ? HtmlUtils.htmlToComponents(candidate.note) : "";
  }, [candidate]);

  return (
    <div className={"PoolCandidateDetails mainRowContainer"}>
      <WaitForData loading={candidateLoading} errors={candidateErrors} onLoaded={() =>
        <>
          <Row className={"topRow"}>
            <Col className="title-col titleRowContainer">
              <Row>
                <Col>
                  <h1>{title}</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2>{subtitle1}</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2 className={"category-name"}>{subtitle2}</h2>
                </Col>
              </Row>
            </Col>
            {hasResume &&
            <Col className="resume-col">
              <PoolCandidateResumeDownloadIcon candidateId={candidate._id}/>
            </Col>
            }
          </Row>
          <Row>
            <Col className={"name-col"}>
              <h3>{t("market:pool_candidates_details_number_label", {number: candidate.number})}</h3>
            </Col>
          </Row>
          <Row>
            <Col className="note-col">
              {candidateNote}
            </Col>
          </Row>
        </>
      }/>
      {/* Display button even if data cannot be loaded */}
      <Row className="button-row">
        <Col>
          <ActionButtonWithIconCancel onClick={onClose}>{t("market:pool_candidates_details_close_action")}</ActionButtonWithIconCancel>
        </Col>
     </Row>
    </div>
  )
}
