import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import "./ReportPreview.scss";
import ActionDiv from "../../../common/widgets/ActionDiv";
import useFormatTimelessDateToLongString from "../../../common/formatting/useFormatTimelessDateToLongString";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import StringUtils from "../../../../utils/StringUtils";

export default function ReportPreview(props) {

  const {report, onClick} = props;
  const formattedReportDate = useFormatTimelessDateToLongString(new Date(report.date));

  const {t, tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ReportPreview");

  return (
    <ActionDiv onClick={() => onClick(report._id)} className="ReportPreview mainRowContainer">
      <Row className={"name-row"}>
        <Col className={"name-col"}>
          {t("reporting:report_preview_title", {date: formattedReportDate})}
        </Col>
      </Row>
      <Row className={"candidate-row"}>
        <Col className={"candidate-col"}>
          <span className={"number"}>{StringUtils.nullToZero(report.candidateCount) + " "}</span>{t("reporting:report_preview_candidate_count", {count: report.candidateCount})}
        </Col>
      </Row>
    </ActionDiv>
  );
};
