import useModuleTranslation from "../infra-no-ui/translation/useModuleTranslation";
import React from "react";

/**
 * Dynamically load translation files pertaining to the reports module.
 * @return {*}
 */
export default function useTranslationReporting() {

  // Imports must be memoized so that they don't change on every render
  const imports = React.useMemo(() => ({
    'en': import('./locales/en/reporting.json'),
    'fr': import('./locales/fr/reporting.json')
  }), []);

  return useModuleTranslation('reporting', imports);
}
