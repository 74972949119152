import React from "react";
import WaitForData from "../../../common/data-fetching/WaitForData";
import useQueryPoolJobsForTalentPool from "./useQueryPoolJobsForTalentPool";
import RouterUtils from "../../../../utils/RouterUtils";
import * as ROUTES from "../../../../constants/routes";
import PoolJobListItem from "./PoolJobListItem";
import PoolJobPreview from "./PoolJobPreview";
import {useNavigate} from "react-router-dom";
import MarketCardList from "../../common/MarketCardList";
import TypeUtils from "../../../../utils/TypeUtils";

export default function PoolJobList(props) {
  const navigate = useNavigate();

  const {talentPoolId} = props;

  const {data: poolJobs, loading: poolJobsLoading, errors: poolJobErrors} = useQueryPoolJobsForTalentPool(talentPoolId);

  // Remove empty jobs
  const nonEmptyJobs = TypeUtils.ensureArray(poolJobs).filter(job => job.candidateCount > 0);

  const onClickPoolJob = (poolJob) => {
    navigate(RouterUtils.injectParamsInRoute(ROUTES.POOL_JOB_DETAILS, {
      talentPoolId: poolJob.talentPoolId,
      poolJobId: poolJob._id
    }))
  }

  return (
    <div className={"PoolJobList"}>
      <WaitForData loading={poolJobsLoading} errors={poolJobErrors} onLoaded={() =>
        <MarketCardList items={nonEmptyJobs} getItemKey={poolJob => poolJob._id} renderItem={poolJob =>
          <PoolJobListItem>
            <PoolJobPreview poolJob={poolJob} onClick={() => onClickPoolJob(poolJob)}/>
          </PoolJobListItem>
        }/>
      }/>
    </div>
  )
};
