import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import "./ReportSummaryBlock.scss";
import Loader from "../../../common/loading-widgets/Loader";
import ReportSummaryStats from "./ReportSummaryStats";
import ReportList from "./ReportList";
import ActionIcon from "../../../common/widgets/ActionIcon";
import BackIcon from "@mui/icons-material/KeyboardBackspace";
import useTranslationMarket from "../../../market/useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import {useNavigate} from "react-router-dom";
import RouterUtils from "../../../../utils/RouterUtils";
import * as ROUTES from "../../../../constants/routes";

export default function ReportSummaryBlock(props) {

  const {report} = props;

  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "PoolJobDetailsBlock");

  const navigate = useNavigate();

  const reportTitle = <span>{report.job.title}</span>;
  const reportSubtitle = <span className={"client-name"}>{report.job.client.name}</span>;

  const goBack = () => {
    navigate(RouterUtils.injectParamsInRoute(ROUTES.REPORT_HOME, {reportId: report._id}));
  }

  return (
    <div className="ReportSummaryBlock mainRowContainer">
      <Row className={"topRow"}>
        <Col className={"title-col titleRowContainer"}>
          <Row>
            <Col className={"back-button-container"}>
              <div className={"back-button"}>
                <ActionIcon onClick={goBack} icon={BackIcon}>{t("market:go_back_action")}</ActionIcon>
              </div>
              <h1>{reportTitle}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>{reportSubtitle}</h2>
            </Col>
          </Row>
        </Col>
        <Col className={"stats-col"}>
          <Loader>
            <ReportSummaryStats reportId={report._id}/>
          </Loader>
        </Col>
      </Row>
      <Row>
        <Col>
          <ReportList reportId={report._id}/>
        </Col>
      </Row>
    </div>
  );
};
