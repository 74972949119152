import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import "./CandidateAnswer.scss";

export default function CandidateAnswer(props) {

  const {question, answer} = props;

  return (
    <div className="CandidateAnswer">
      <Row>
        <Col className={"question-col"}>
          {question.text}
        </Col>
      </Row>
      <Row>
        <Col className={"answer-col"}>
          {answer ? answer.text : <>&mdash;</>}
        </Col>
      </Row>
    </div>
  );
};
