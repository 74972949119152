import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import PoolCandidateList from "../../candidates/view/PoolCandidateList";
import "./JobCategoryDetailsBlock.scss";
import {useNavigate} from "react-router-dom";
import RouterUtils from "../../../../utils/RouterUtils";
import * as ROUTES from "../../../../constants/routes";
import ActionIcon from "../../../common/widgets/ActionIcon";
import BackIcon from "@mui/icons-material/KeyboardBackspace";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";

export default function JobCategoryDetailsBlock(props) {

  const {jobCategory} = props;

  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "PoolJobDetailsBlock");

  const navigate = useNavigate();

  const talentPoolId = jobCategory.poolJob.talentPool._id;
  const talentPoolName = jobCategory.poolJob.talentPool.name;
  const poolJobName = jobCategory.poolJob.name;
  const poolJobId = jobCategory.poolJob._id;

  const goBack = () => {
    navigate(RouterUtils.injectParamsInRoute(ROUTES.POOL_JOB_DETAILS, {talentPoolId, poolJobId}));
  }

  const jobCategoryTitle = <span>{talentPoolName}</span>;
  const jobCategorySubtitle1 = <span className={"job-name"}>{poolJobName}</span>;
  const jobCategorySubtitle2 = <span className={"category-name"}>{jobCategory.name}</span>;

  return (
    <div className="JobCategoryDetailsBlock mainRowContainer">
      <Row className={"topRow"}>
        <Col className={"title-col titleRowContainer"}>
          <Row>
            <Col className={"back-button-container"}>
              <div className={"back-button"}>
                <ActionIcon onClick={goBack} icon={BackIcon}>{t("market:go_back_action")}</ActionIcon>
              </div>
              <h1>{jobCategoryTitle}</h1>
            </Col>
          </Row>
          <Row >
            <Col>
              <h2>{jobCategorySubtitle1}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className={"category-name"}>{jobCategorySubtitle2}</h2>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <PoolCandidateList jobCategoryId={jobCategory._id} title={jobCategoryTitle}
                             subtitle1={jobCategorySubtitle1} subtitle2={jobCategorySubtitle2}/>
        </Col>
      </Row>
    </div>
  );
};
